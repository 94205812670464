import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseHttpService, ConfigService } from '@shared/services';
import { ResponseError } from '@shared/models';
import { Observable } from 'rxjs';
import { LanguageService } from '@shared/services/language.service';
import { FaqContent } from '@shared/models/faq-content';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class FaqService extends BaseHttpService {
  constructor(
    httpClient: HttpClient,
    translateService: TranslateService,
    configService: ConfigService,
    private languageService: LanguageService
  ) {
    super(httpClient, configService, translateService);
  }

  public getFaqContent(language?: string): Observable<FaqContent | ResponseError> {
    const lang = !language ? this.languageService.getActiveLanguage().isoCode : language;
    return this.get<FaqContent>('/faq/getContent/' + lang);
  }

}
