import { Component, OnInit, OnDestroy } from '@angular/core';
import { IntroSection } from '@shared/models/intro-section';
import { ResponseError } from '@shared/models';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { Logger } from '@shared/logging/Logger';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { FaqService } from './services/faq.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  public content: ContentSection[];
  public introSection: IntroSection;
  private localizeSubscription: Subscription;

  constructor(private spinnerService: NgxSpinnerService,
    private logger: Logger,
    private localizeService: LocalizeRouterService,
    private faqService: FaqService) { }

  ngOnInit() {
    this.prepareIntroSectionValues();
    this.updateContent();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateContent(language);
    });
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/faq/FAQ-Hero.png';
    this.introSection.Title = 'FAQ_LetUsHelp';
    this.introSection.Description = 'FAQ_LetUsHelp_Questions';
  }

  updateContent(language?: string) {
    this.spinnerService.show();

    this.faqService.getFaqContent(language)
      .subscribe((response) => {
        if (response instanceof ResponseError) {
          this.logger.error(response);
          this.spinnerService.hide();
          return;
        }
        this.content = response.content;
        this.spinnerService.hide();
      })
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
