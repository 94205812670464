import { Component, OnInit, OnDestroy } from '@angular/core';
import { ResponseError } from '@shared/models/response-error';
import { IntroSection } from '@shared/models/intro-section';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ContractualSummaryService } from '../_services/contractual-summary.service';

import { Logger } from '@shared/logging/Logger';
import { Plan } from '@app/home/home-plans/_models/plan';

@Component({
  selector: 'app-contractual-summary',
  templateUrl: './contractual-summary.component.html',
  styleUrls: ['./contractual-summary.component.scss']
})
export class ContractualSummaryComponent implements OnInit, OnDestroy {
  public introSection: IntroSection;
  public content: ContentSection[];
  public legal: string;
  public localPlans: Plan[] = [];
  private localizeSubscription: Subscription;

  constructor(
    private localizeService: LocalizeRouterService,
    private spinnerService: NgxSpinnerService,
    private contractualSummaryService: ContractualSummaryService,
    private logger: Logger) { }

  ngOnInit() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.prepareIntroSectionValues();
    this.updateContent();
    this.localizeSubscription = this.localizeService.routerEvents.subscribe((language: string) => {
      this.updateContent(language);
    });
  }

  updateContent(language?: string) {
    this.spinnerService.show();
    this.contractualSummaryService.getContractualSummary(language).subscribe((response) => {
      if (response instanceof ResponseError) {
        this.logger.error(response);
        this.spinnerService.hide();
        return;
      }
      this.content = response.content;
      this.populateLocalPlans();
      window.scrollTo(0, 0);
      this.spinnerService.hide();
    });
  }

  populateLocalPlans() {
    this.contractualSummaryService.getLocalPlans().subscribe((plans) => {
      this.localPlans = plans;
    });
  }

  prepareIntroSectionValues() {
    this.introSection = new IntroSection();
    this.introSection.image = './assets/terms-and-conditions/Terms-Hero.png';
    this.introSection.Title = 'Validity_Plan_Contract_Summary';
  }

  ngOnDestroy(): void {
    this.localizeSubscription.unsubscribe();
  }
}
