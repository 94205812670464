import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseError, SessionKeys } from '@shared/models';
import { BaseHttpService, ConfigService, LanguageService, UserContextService } from '@shared/services';
import { HttpClient } from '@angular/common/http';
import { CountriesService } from '@shared/services/countries.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from '@shared/services';
import { PlansService } from '@app/home/home-plans/_services/plans.service';
import { AlertService } from '@shared/services';
import { Plan } from '@app/home/home-plans/_models/plan';
import { TermsAndConditionsContent } from '@shared/models/terms-and-conditions-content';
@Injectable()
export class ContractualSummaryService extends BaseHttpService {
  sessionKeys = new SessionKeys();
  homeCountryCode: string;
  private plans: Plan[] = [];

  constructor(
    httpClient: HttpClient,
    configService: ConfigService,
    translateService: TranslateService,
    private countriesService: CountriesService,
    private userContextService: UserContextService,
    private languageService: LanguageService,
    private locationService: LocationService,
    private plansService: PlansService,
    private alertService: AlertService
  ) {
    super(httpClient, configService, translateService);
  }

  public getContractualSummary(language?: string): Observable<TermsAndConditionsContent | ResponseError> {
    const lang = !language ? this.languageService.getActiveLanguage().isoCode : language;
    this.fetchAndFilterBundleRates(lang);
    return this.get<TermsAndConditionsContent>('/legal/getTermsAndConditions/' + this.getCountry(lang));
  }

  private async fetchAndFilterBundleRates(language?: string) {
    try {
      let userCountry = await this.getUserCountry();
      if (!userCountry) {
        userCountry = this.getCountry(language || this.languageService.getActiveLanguage().isoCode);
      }
      this.plansService.getBundleRates().subscribe({
        next: (value: Plan[] | ResponseError) => {
          if (value instanceof Array) {
            this.plans = value.filter(plan => plan.countryISOCode.toLowerCase() === userCountry.toLowerCase());
            console.log(this.plans);
          }
        },
        error: (err: ResponseError) => {
          this.alertService.error(err.message, 0, true, false);
        }
      });
    } catch (error) {
      console.error('Error fetching localized bundle rates:', error);
    }
  }

  public getLocalPlans(): Observable<Plan[]> {
    return new Observable((observer) => {
      if (this.plans.length > 0) {
        observer.next(this.plans);
      } else {
        this.fetchAndFilterBundleRates().then(() => {
          observer.next(this.plans);
        }).catch(error => {
          observer.error(error);
        });
      }
    });
  }

  private async getUserCountry(): Promise<string> {
    try {
      const countryCode = await this.locationService.getClientCountryCode().toPromise();
      return countryCode || null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private getCountry(language: string): string {
    this.homeCountryCode = this.userContextService.userContext.homeCountryCode;
    if (this.homeCountryCode) {
      return this.homeCountryCode;
    } else {
      return this.countriesService.getDefaultIsoCodeByLanguageCode(language);
    }
  }
}
