<h2 innerHtml="{{activeMultipleContent[0]?.groupTitle}}" #multipleContentDiv class="active-content-header"
  [attr.data-test-id]="'faq-header-' + activeMultipleContent[0]?.groupTitle"></h2>
<div class="container-fluid" [attr.data-test-id]="'main-content-' + trim(activeMultipleContent[0].groupTitle)">

  <ul class="faq-questions-list">
    <li *ngFor="let section of activeMultipleContent,let index = index">
      <input type="checkbox" checked
        [attr.data-test-id]="'checkbox-option-' + trim(activeMultipleContent[0].groupTitle) + '-' + index">
      <i></i>
      <h5 [attr.data-test-id]="section.id">
        {{section.header}}
      </h5>
      <div *ngFor="let subSection of section.subSections, let i = index">
        <p *ngIf="section.id !== 'faq-details-change-home-country' && section.id !== 'faq-details-bundles'"
          [attr.data-test-id]="section.id+'-text-'+i" innerHTML="{{subSection.content}}" class="content-element"></p>
        <ul *ngIf="section.id === 'faq-details-change-home-country'" class="home-country"
          data-test-id="change-home-country">
          <li id="end-of-paragraph" data-test-id="change-home-county-text">
            {{getContactUsContent(subSection.content)[0]}}
            <a class="standard-link" [routerLink]="['/contactUs'  | localize]"
              data-test-id="faq-home-country-contact-us-link">{{getContactUsContent(subSection.content)[1]}}</a>
          </li>
        </ul>
        <div *ngIf="section.id === 'faq-details-bundles'">

          <p *ngIf="i === 0 || i === 4" [attr.data-test-id]="section.id+'-text-'+i">{{subSection.content}}</p>
          <div *ngIf="i === 0 && section.id === 'faq-details-bundles'">
            <app-bundle-details></app-bundle-details>
          </div>
        </div>
        <ul *ngIf="subSection.listItems?.length > 0" class="{{subSection.listItems[0]?.id}}"
          [attr.data-test-id]="subSection.listItems[0]?.id">
          <li *ngFor="let listItem of subSection.listItems, let i = index"
            [attr.data-test-id]="(listItem.faqId === 'purchase-plan'? listItem.id + '-step-'+i: listItem.id +'-'+ trim(listItem.value))">
            <span innerHtml=" {{listItem.faqId === 'purchase-plan' && i === 0? '': listItem.value}}"></span>
            <div *ngIf="listItem.faqId === 'purchase-plan' && i === 0">
              {{getRegisterContent(listItem.value)[0]}}
              <a class="standard-link" [routerLink]="['/register' | localize]" data-test-id="faq-portal-register-link">
                {{getRegisterContent(listItem.value)[1]}}</a>
              {{getRegisterContent(listItem.value)[2]}}
            </div>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>