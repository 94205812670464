import { Component, Input } from '@angular/core';
import { ContentSection } from '@shared/models/content-section';
import { Country } from '@shared/models';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
  styleUrls: ['./faq-details.component.scss']
})
export class FaqDetailsComponent {
  @Input() activeMultipleContent: ContentSection[];
  registerSections: string[] = [];
  contactusSections: string[] = [];
  // Country
  countries: Country[];
  defaultCountryCode: number;
  constructor(
  ) {
  }

  parseLinkRef(content: string, valueToMatch: string): string[] {
    const regExp = /[^\[\]]/;
    if (content.match(valueToMatch)) {
      content = content.replace(valueToMatch, '');
      return regExp.exec(content).input.split('#');
    }
  }

  getRegisterContent(content: string): string[] {
    this.registerSections = [];
    this.registerSections = this.parseLinkRef(content, 'register#link#');
    return this.registerSections;
  }

  getContactUsContent(content: string): string[] {
    if (this.contactusSections.length === 0) {
      this.contactusSections = this.parseLinkRef(content, 'contactus#');
    }
    return this.contactusSections;
  }

  trim(value: string): string {
    return value.replace(/\s+/g, '').toLowerCase();
  }
}
