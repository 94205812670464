import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContentSection } from '@shared/models/content-section';
import { ScrollToService } from '@shared/services/scrollto.service';

@Component({
  selector: 'app-selectable-content',
  templateUrl: './selectable-content.component.html',
  styleUrls: ['./selectable-content.component.scss']
})
export class SelectableContentComponent implements OnInit, OnChanges {
  @Input() content: ContentSection[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input() activeMultipleContent: ContentSection[];
  @ViewChild('singleContentDiv') singleContentDiv: ElementRef;
  @ViewChild('multipleContentDiv') multipleContentDiv: ElementRef;
  public activeContent: ContentSection;
  // public activeMultipleContent: ContentSection[] = new Array<ContentSection>();

  public contentType: string;
  public headers: any[];
  public activeGroupTitle: string;
  constructor(private scrollService: ScrollToService, private translationsService: TranslateService) { }


  ngOnInit() {
    this.initializeActiveContent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.content.currentValue) {
      this.content = changes.content.currentValue;
      this.initializeActiveContent();
    }
  }

  setActiveContent(id: string) {
    let valueToCompare: string;
    //let activeContent: ElementRef;
    this.activeMultipleContent = new Array<ContentSection>();
    for (const section of this.content) {
      if (this.contentType !== 'faq') {
        valueToCompare = section.id;
        //activeContent = this.singleContentDiv;
        if (this.isActiveHeader(id, valueToCompare)) {
          this.activeContent = section;
          break;
        }
      } else {
        valueToCompare = section.groupTitle;
        //activeContent = this.multipleContentDiv;
        this.activeGroupTitle = id;
        if (this.isActiveHeader(id, valueToCompare)) {
          this.addActiveContent(section);
        }
        // this.scrollToTop(activeContent.nativeElement);
      }
    }
  }

  isActiveHeader(header: string, valueToCompare: string): boolean {
    let isActiveHeader = false;
    if (valueToCompare !== undefined && valueToCompare !== null) {
      if (valueToCompare === header) {
        isActiveHeader = true;
      }
    }
    return isActiveHeader;
  }

  addActiveContent(section: ContentSection) {
    this.activeMultipleContent.push(section);
  }

  initializeActiveContent() {
    if (this.content?.length > 0) {
      this.contentType = this.content[0].contentType;
      if (this.contentType !== 'faq') {
        this.activeContent = this.content[0];
        this.headers = this.content;
      } else {
        this.setActiveContent(this.translationsService.instant('Faq_Details_About_Title'));
        this.headers = Array.from(new Set(this.content.map(item => item.groupTitle)));
      }
    }
  }

  scrollToTop(element) {
    this.scrollService.scrollTo(element, 500, -200);
  }

  trim(value: string): string {
    return value.replace(/\s+/g, '').toLocaleLowerCase();
  }
}
