import { Injectable } from '@angular/core';
import { TermsAndConditionsService } from '../../app/legal/_services/terms-and-conditions.service';
import { PrivacyNoticeService } from '../../app/legal/_services/privacy-notice.service';
import { ContractualSummaryService } from '../../app/legal/_services/contractual-summary.service'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ResponseError } from '@shared/models/response-error';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '@shared/logging/Logger';
import { ContentSection } from '@shared/models/content-section';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class LegalResolverService  {

  constructor(
    private termsAndConditionsService: TermsAndConditionsService,
    private privacyNoticeService: PrivacyNoticeService,
    private contractualSummaryService: ContractualSummaryService,
    private spinnerService: NgxSpinnerService,
    private logger: Logger) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContentSection | ResponseError> {
    this.spinnerService.show();
    if (route.routeConfig.path === 'termsAndConditions') {
      return this.termsAndConditionsService.getTermsAndConditions().pipe(
        catchError(err => {
          this.logger.error(err);
          this.spinnerService.hide();
          return of(err);
        }));
    }
    if (route.routeConfig.path === 'privacyNotice') {
      return this.privacyNoticeService.getPrivacyNotice().pipe(
        catchError(err => {
          this.logger.error(err);
          this.spinnerService.hide();
          return of(err);
        }));
    }
    if (route.routeConfig.path === 'contractualSummary') {
      return this.contractualSummaryService.getContractualSummary().pipe(
        catchError(err => {
          this.logger.error(err);
          this.spinnerService.hide();
          return of(err);
        }));
    }
  }
}
