import { NgModule, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
// Shared
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeService } from '@shared/services/theme-service.service';
import { ThemeComponent } from '@shared/components/theme.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { FloatingAlertComponent } from '@shared/components/floating-alert/floating-alert.component';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from '@shared/guards/auth.guard';
import { IntroSectionComponent } from '@shared/components/intro-section/intro-section.component';
import { NotForAuthenticatedGuard } from '@shared/guards/not-for-authenticated.guard';
import { AuthInterceptor } from '@shared/interceptors/auth.interceptor';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SelectableContentComponent } from '@shared/components/selectable-content/selectable-content.component';
import { PipeModule } from '@shared/pipes/pipe.module';
// Services
import { MonitoringService } from '@shared/services/monitoring.service';
import { ConfigService } from '@shared/services/config.service';
import { AlertService } from '@shared/services/alert.service';
import { DataService } from '@shared/services/data.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { UserContextService } from '@shared/services/user-context.service';
import { AccountRegistrationService } from './registration/_services/account-registration.service';
import { AccountResetPasswordService } from './login/_services/account-reset-password.service';
import { AccountActivateService } from './registration/_services/account-activate.service';
import { VehicleService } from '../app/vehicles/_services/index';
import { CountriesService } from '@shared/services/countries.service';
import { AccountIdentificationService } from './account/_services/account-identification.service';
import { IdentificationValidationService } from './user/_services/identification-validation.service';
import { TermsAndConditionsService } from './legal/_services/terms-and-conditions.service';
import { PrivacyNoticeService } from './legal/_services/privacy-notice.service';
import { ContractualSummaryService } from './legal/_services/contractual-summary.service';
import { RegistrationContextService } from '@shared/services/registration-context.service';
import { GoogleAnalyticsEcommerceService } from '@shared/services/ga-ecommerce.service';
import { AnalyticsToolsService } from '@shared/services/analytics-tools.service';
import { RedirectContextService } from '@shared/services/redirect-context.service';
import { FormatSmsService } from '@shared/services/format-sms.service';
import { TeaserEligibilityService } from './registration/_services/teaser-eligibility.service';
import { PriceFormatLocalisationService } from '@shared/services/PriceFormatLocalisationService';

import {RumService} from'@shared/services/datadog-rum.service';
// Home components
import { HomeComponent } from './home/home.component';
import { MainCaptionComponent } from './home/main-caption/main-caption.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HotspotComponent } from './home/hotspot/hotspot.component';
import { FavouriteContentComponent } from './home/favourite-content/favourite-content.component';
import { InfoComponent } from './app-information/info/info.component';
import { ConnectivityComponent } from './home/connectivity/connectivity.component';
import { CompatibilityComponent } from './home/compatibility/compatibility.component';
import { HomePlansComponent } from './home/home-plans/home-plans.component';

// Getting started components
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { FaqComponent } from './faq/faq.component';

// Login components
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ForgotPasswordEmailComponent } from './login/forgot-password/forgot-password-email/forgot-password-email.component';
import { ResetPasswordExpiredComponent } from './login/reset-password/reset-password-expired/reset-password-expired.component';
import { LoginService } from './login/_services/login.service';
import { RedirectComponent } from './redirect/redirect.component';
import { ResetPasswordEmailFailedComponent } from './login/forgot-password/reset-password-email-failed/reset-password-email-failed.component';
// Vehicle components
import { VehicleAddComponent } from './vehicles/vehicle-add/vehicle-add.component';

// Dashboard components
import { DashboardModule } from './dashboard/dashboard.module';

// Legal Components
import { TermsAndConditionsComponent } from './legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyNoticeComponent } from './legal/privacy-notice/privacy-notice.component';
import { LegalResolverService } from '@shared/resolvers/legal-resolver.service';
import { ContractualSummaryComponent } from './legal/contractual-summary/contractual-summary.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { SpeedometerComponent } from './layout/header/icons/speedometer/speedometer.component';
import { ShoppingCartComponent } from './layout/header/icons/shopping-cart/shopping-cart.component';
import { HistoryIconComponent } from './layout/header/icons/history-icon/history-icon.component';
import { SilhouetteComponent } from './layout/header/icons/silhouette/silhouette.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LanguageService } from '@shared/services/language.service';
import { Logger } from '@shared/logging/Logger';
import { ConsoleSink } from '@shared/logging/sinks/console.sink';
import { AppInsightsSink } from '@shared/logging/sinks/appinsights.sink';
import { environment } from 'environments/environment';
import { FaqDetailsComponent } from './faq/faq-details/faq-details.component';
import { AboutCubicComponent } from './about-cubic/about-cubic.component';
import { ProvidesConnectivityComponent } from './about-cubic/provides-connectivity/provides-connectivity.component';
import { DrivingConnectivityComponent } from './about-cubic/driving-connectivity/driving-connectivity.component';
import { OurBelieversComponent } from './about-cubic/our-believers/our-believers.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ImpressumComponent } from './about-cubic/impressum/impressum.component';
import { ContactUsFormComponent } from './contact-us/contact-us-form/contact-us-form.component';
import { ContactUsService } from './contact-us/_services/contact-us.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserUpdateNotificationComponent } from './browser-update-notification/browser-update-notification.component';
import { GlobalErrorHandler } from '@shared/services/global-error-handler';

// FAQ
import { FaqService } from './faq/services/faq.service';
import { PlanTileComponent } from './home/home-plans/plan-tile/plan-tile.component';
import { PlansService } from './home/home-plans/_services/plans.service';
import { LocationService } from '@shared/services';
import { VehicleImagesUrlService } from './vehicles/_services/vehicle-images-url.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ScrollToService } from '@shared/services/scrollto.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { WebpackTranslateLoader } from '@shared/factories/translate-loader-factory';
import { RedirectService } from './redirect/_services/redirect.service';
import { SSOAuthenticationModule } from './sso-authentication/sso-authentication.module';
import { AlertComponent } from '@shared/components/alert/alert.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    FloatingAlertComponent,
    InfoComponent,
    ThemeComponent,
    HomeComponent,
    MainCaptionComponent,
    HotspotComponent,
    FavouriteContentComponent,
    ConnectivityComponent,
    CompatibilityComponent,
    GettingStartedComponent,
    FaqComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ForgotPasswordEmailComponent,
    ResetPasswordExpiredComponent,
    HomePlansComponent,
    VehicleAddComponent,
    TermsAndConditionsComponent,
    IntroSectionComponent,
    PrivacyNoticeComponent,
    CookiePolicyComponent,
    SpeedometerComponent,
    ShoppingCartComponent,
    HistoryIconComponent,
    SilhouetteComponent,
    NotFoundComponent,
    FaqComponent,
    FaqDetailsComponent,
    AboutCubicComponent,
    ProvidesConnectivityComponent,
    ContractualSummaryComponent,
    DrivingConnectivityComponent,
    OurBelieversComponent,
    ContactUsComponent,
    ContactUsFormComponent,
    ImpressumComponent,
    BrowserUpdateNotificationComponent,
    SelectableContentComponent,
    RedirectComponent,
    ResetPasswordEmailFailedComponent,
    PlanTileComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    DashboardModule,
    SSOAuthenticationModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
        deps: [ConfigService]
      }
    }),
    NgxSpinnerModule,
    SharedComponentsModule,
    BrowserAnimationsModule,
    PipeModule.forRoot()
  ],
  exports: [CommonModule, TranslateModule],
  providers: [
    MonitoringService,
    ThemeService,
    ConfigService,
    AccountRegistrationService,
    AccountResetPasswordService,
    RedirectService,
    AccountActivateService,
    AlertService,
    DataService,
    CookieService,
    VehicleService,
    CountriesService,
    RedirectContextService,
    AccountIdentificationService,
    IdentificationValidationService,
    AuthenticationService,
    LoginService,
    AuthGuard,
    TermsAndConditionsService,
    ContractualSummaryService,
    PrivacyNoticeService,
    LegalResolverService,
    NotForAuthenticatedGuard,
    GoogleAnalyticsEcommerceService,
    FaqService,
    AnalyticsToolsService,
    RumService,
    FormatSmsService,
    ScrollToService,
    DeviceDetectorService,
    TeaserEligibilityService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },

    UserContextService,
    LanguageService,
    RegistrationContextService,
    {
      provide: Logger,
      useFactory: loggerServiceFactory,
      deps: [ConfigService, MonitoringService]
    },
    ContactUsService,                                              
    [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    PlansService,
    LocationService,
    VehicleImagesUrlService,
    PriceFormatLocalisationService

  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

export function loggerServiceFactory(configService: ConfigService, monitoringService: MonitoringService) {
  if (environment.local) {
    return new Logger()
      .logTo(new ConsoleSink())
      .minLoggingLevel(configService.config.minLogLevel)
      .create();
  }
  return new Logger()
    .logTo(new AppInsightsSink(monitoringService))
    .minLoggingLevel(configService.config.minLogLevel)
    .create();
}