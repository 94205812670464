<div class="selectable-content-container">
  <div class="container-fluid">
    <div class="row d-block d-md-none">
      <select class="form-control col-12 selectable-content-header-dropdown"
        (change)="setActiveContent($event.target.value)">
        <option
          [attr.data-test-id]="contentType + '-dropdown-option-'  + (contentType !== 'faq'? section.id: trim(section))"
          *ngFor="let section of headers" [value]="contentType !== 'faq'? section.id: section"
          innerHtml="{{contentType !== 'faq'? (section.header === null ? (section.headerTranslation | translate) : section.header):section}}">
        </option>
      </select>
    </div>
    <div class="row">
      <div class="d-none d-md-block col-md-5 col-lg-4 col-xl-3">
        <ul class="sidebar-list">
          <li *ngFor="let section of headers" class="sidebar-list-items">
            <a [attr.data-test-id]="contentType + '-sidebar-option-' + (contentType !== 'faq'? section.id: trim(section))"
              (click)="setActiveContent(contentType !== 'faq'? section.id: section)" class="sidebar-link"
              [ngClass]="{'faq-sidebar-link': contentType === 'faq'}"
              innerHtml="{{contentType !== 'faq'? (section.header === null ? (section.headerTranslation | translate) : section.header): section}}"></a>
          </li>
        </ul>
      </div>

      <div *ngIf="contentType !== 'faq'" class="col-12 col-md-7 col-lg-8 col-xl-7">
        <h3 innerHtml="{{activeContent.header}}" #singleContentDiv class="active-content-header"
          [attr.data-test-id]="activeContent.contentType + '_header_' + activeContent.id"></h3>
        <div *ngFor="let subSection of activeContent.subSections" class="active-content">
          <h4 [attr.data-test-id]="activeContent.contentType + '_sub_header_' + activeContent.id"
            innerHtml="{{subSection.header === undefined? (subSection.headerTranslation | translate): subSection.header}}">
          </h4>
          <p [attr.data-test-id]="activeContent.contentType + '_selected_content_' + activeContent.id"
            innerHtml="{{subSection.content === null? (subSection.contentTranslation | translate): subSection.content}}"
            class="active-paragraph"></p>
        </div>
      </div>
      <div *ngIf="contentType === 'faq'" class="col-12 col-md-7 col-lg-8 col-xl-7">
        <app-faq-details [activeMultipleContent]="activeMultipleContent"></app-faq-details>
      </div>
    </div>
  </div>
</div>