import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Guard
import { NotForAuthenticatedGuard } from '@shared/guards/not-for-authenticated.guard';

// Content
import { HomeComponent } from './home/home.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { AboutCubicComponent } from './about-cubic/about-cubic.component';
import { ContactUsComponent } from './/contact-us/contact-us.component';

// Login
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ForgotPasswordEmailComponent } from './login/forgot-password/forgot-password-email/forgot-password-email.component';
import { ResetPasswordExpiredComponent } from './login/reset-password/reset-password-expired/reset-password-expired.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ResetPasswordEmailFailedComponent } from './login/forgot-password/reset-password-email-failed/reset-password-email-failed.component';
// Vehicle
import { VehicleAddComponent } from './vehicles/vehicle-add/vehicle-add.component';
import { VehicleRemoveComponent } from './vehicles/vehicle-remove/vehicle-remove.component';

// Legal
import { TermsAndConditionsComponent } from './legal/terms-and-conditions/terms-and-conditions.component';
import { PrivacyNoticeComponent } from './legal/privacy-notice/privacy-notice.component';
import { ContractualSummaryComponent } from './legal/contractual-summary/contractual-summary.component';

// Dashboard account
import { AccountActivateComponent } from './registration/account-activate/account-activate.component';
import { AccountActivateExpiredComponent } from './registration/account-activate/account-activate-expired/account-activate-expired.component';
import { SubmitIdentityComponent } from './user/identification/id-verification/submit-identity.component';
import { AccountDeleteComponent } from './account/account-delete/account-delete.component';
// Resolvers
import { LegalResolverService } from '@shared/resolvers/index';

// Routes
import { dashboardRoutes } from './dashboard/dashboard.routing';
import { productRoutes } from './product/product.routing';
import { NotFoundComponent } from './not-found/not-found.component';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { Location } from '@angular/common';
import { FaqComponent } from './faq/faq.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageCode } from '@shared/models/language-code';
import { ssoAuthenticationRoutes } from './sso-authentication/sso-authentication.routing';

const appRoutes: Routes = [
    // Landing page
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'redirect', component: RedirectComponent },
    // Login
    { path: 'login', component: LoginComponent, canActivate: [NotForAuthenticatedGuard] },
    { path: 'resetPassword', component: ResetPasswordComponent },
    { path: 'resetLinkExpired', component: ResetPasswordExpiredComponent },
    { path: 'forgotPassword', component: ForgotPasswordComponent },
    { path: 'checkEmail', component: ForgotPasswordEmailComponent },
    { path: 'emailFailed', component: ResetPasswordEmailFailedComponent },
    { path: 'activateAccount', component: AccountActivateComponent },
    { path: 'activationLinkExpired', component: AccountActivateExpiredComponent },
    // Legal
    { path: 'termsAndConditions', component: TermsAndConditionsComponent, resolve: { legal: LegalResolverService } },
    { path: 'gettingStarted', component: GettingStartedComponent },
    { path: 'privacyNotice', component: PrivacyNoticeComponent },
    { path: 'aboutCubic', component: AboutCubicComponent },
    { path: 'contactUs', component: ContactUsComponent },
    { path: 'contractualSummary', component: ContractualSummaryComponent, resolve: { legal: LegalResolverService } },
    // Registration
    { path: 'register', loadChildren: () => import('app/registration/registration.module').then(m => m.RegistrationModule) },
    
    // Dasboard vehicle
    { path: 'dashboard/vehicle/add', component: VehicleAddComponent },
    { path: 'dashboard/vehicle/remove/:vin', component: VehicleRemoveComponent },
    // Dasboard account
    { path: 'dashboard/submitIdentity', component: SubmitIdentityComponent },
    { path: 'dashboard/deleteAccount', component: AccountDeleteComponent },
    ...dashboardRoutes,
    ...productRoutes,
    ...ssoAuthenticationRoutes,
    { path: '**', component: NotFoundComponent }

];

export function ManualLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
    return new ManualParserLoader(translate, location, settings, Object.values(LanguageCode));
}


@NgModule({
    imports: [
        //onSameUrlNavigation force angular to initialize the components when acessing the same URL (Ex: changing languages)
        RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' }),
        LocalizeRouterModule.forRoot(appRoutes, {
            parser: {
                provide: LocalizeParser,
                useFactory: ManualLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings]
            },
            
        })
    ],
    exports: [
        RouterModule, LocalizeRouterModule
    ]
})
export class AppRoutingModule { }
