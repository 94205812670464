import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseError, SessionKeys } from '@shared/models';
import { BaseHttpService, ConfigService, DataService, LanguageService, UserContextService } from '@shared/services';
import { CountriesService } from '@shared/services/countries.service';
import { HttpClient } from '@angular/common/http';
import { PrivacyNoticeContent } from '@shared/models/privacy-notice-content';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PrivacyNoticeService extends BaseHttpService {
  sessionKeys = new SessionKeys();
  homeCountryCode: string;
  constructor(
    httpClient: HttpClient,
    configService: ConfigService,
    translateService: TranslateService,
    private dataService: DataService,
    private countriesService: CountriesService,
    private userContextService: UserContextService,
    private languageService: LanguageService) {
    super(httpClient, configService, translateService);
  }

  public getPrivacyNotice(language?: string): Observable<PrivacyNoticeContent | ResponseError> {
    const lang = !language ? this.languageService.getActiveLanguage().isoCode : language;
    return this.get<PrivacyNoticeContent>('/legal/getPrivacyNotice/' + this.getCountry(lang));
  }

  protected getSessionStorage(key: string) {
    return this.dataService.getSession(key);
  }

  private getCountry(language: string) {
    this.homeCountryCode = this.userContextService.userContext.homeCountryCode;
    if (this.homeCountryCode !== null && this.homeCountryCode !== '') {
      return this.homeCountryCode;
    } else {
      return this.countriesService.getDefaultIsoCodeByLanguageCode(language);
    }
  }
}
